.App {
  font-family: monospace, serif;
  font-size: 0.9rem;
  color: #000000;
  padding: 0 0 64px 0;
  margin: 0 auto;
  max-width: 1000px;
}

#bio {
  margin-top: 32px;
  margin-bottom: 48px;
}

#bio p {
  color: #666;
  line-height: 1.4rem;
}

#bio span {
  color: #000;
}

.section {
  margin: 32px 24px 0 24px;
}

.link-item {
  line-height: 1.5rem;
  margin-bottom: 8px;
}

.details {
  color: #999999;
  margin: 0 16px;
}

.highlight {
  color: #cc3333;
  margin: 0 16px;
}

#SocialCTA {
  display: flex;
  background: #e9e9e9;
  padding: 36px 24px;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.previewImg {
  display: block;
  background: #fff;
  width: 224px;
  height: 224px;
  margin-right: 16px;
  border: 1px solid #000;
  background-image: url('./assets/social-preview.png');
  background-size: cover;
  background-position: 50% 50%;
}

.previewDetails {
}

.previewTitle {
  margin: 0;
}

.previewCaption {
  margin: 12px 0;
  font-size: 0.9rem;
}

.previewCategory {
  color: #666;
  font-size: 0.9rem;
}

.previewCategoryList li {
  line-height: 1.1rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 800px) {
  #SocialCTA {
    flex-direction: row;
    justify-content: flex-start;
  }

  .previewImg {
    width: 112px;
    height: 112px;
  }
}

@media (max-width: 600px) {
  .App {
    font-size: 0.9rem;
    padding: 0;
  }

  #SocialCTA {
    display: flex;
    padding: 24px;
  }

  .previewCaption,
  .previewCategory {
    font-size: 0.7rem;
  }

  .section {
    margin: 18px 24px 0 24px;
  }

  .link-item {
    line-height: 1.2rem;
  }
}
